.map {
  height: 100%;
}

.map .ol-custom-overviewmap,
.map .ol-custom-overviewmap.ol-uncollapsible {
  bottom: auto;
  left: auto;
  right: 15px;
  top: 15px;
  display: flex;
}

/* .map .ol-custom-overviewmap:not(.ol-collapsed) {
  border: 1px solid black;
} */

.map .ol-custom-overviewmap .ol-overviewmap-map {
  border: none;
  margin: 0;
}

.map .ol-custom-overviewmap .ol-overviewmap-box {
  border: 3px solid black;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 2px black);
  border-radius: 2px;
}

.map .ol-custom-overviewmap:not(.ol-collapsed) button {
  bottom: auto;
  left: auto;
  right: 1px;
  top: 1px;
}

.map .ol-rotate {
  top: 170px;
  right: 0;
}

.ol-control {
  padding: 0;
  filter: drop-shadow(0px 5px 16px rgba(0, 0, 0, 0.3));
  border-radius: 5px;
}

.ol-selectable,
.ol-tooltip-measure {
  pointer-events: none !important;
}

/* .ol-selectable:hover, */
.ol-tooltip-measure {
  opacity: 0.4 !important;
}

.ol-control button {
  display: none;
}

.ol-overviewmap-map > .ol-viewport {
  border-radius: 3px;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: var(--color-emerald);
  color: white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.9);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: var(--color-emerald);
}

.ant-select-dropdown {
  width: 100px !important;
}
/* .ant-select-open .ant-select-selector {
  background-color: #2b2b2b !important;
} */

.ol-rotate {
  display: none;
}
