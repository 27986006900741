.ant-picker-header .ant-picker-header-super-prev-btn,
.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-super-next-btn,
.ant-picker-header .ant-picker-header-next-btn {
  display: flex !important;
  align-items: center !important;
}
.ant-picker-header {
  border-bottom: 1px solid var(--color-border-1);
}

.ant-picker-range {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
}

.ant-picker-range-arrow {
  visibility: hidden;
}
