.ol-scale-ruler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__rule-x {
    background: var(--color-bg-3); 
    height: 16px;
    width: 100%;
    display: flex;
  }
  &__rule-y {
    background: var(--color-bg-3); 
    height: 100%;
    width: 16px;
    display: flex;
    flex-direction: column;
  }
  &__serif-x {
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
  }
  &__serif-y {
    border-top: 1px solid #eee;
  }
  &__rule-y {
    height: 100%;
    border-left: 1px solid #eee;
  }
  
}

.ol-overlaycontainer-stopevent .ol-scale-line {
  right: 8px !important;
}

.ol-scale-shadow-canvas {
  position: relative;
  .ol-scale-line {
    color: black;
    font-weight: 600;
    background: rgba(255,255,255,0.7);
    position: absolute;
    bottom: 8px;
    left: auto;
    right: 32px;
    display: inline-flex;
    &-scope {
      margin-left: 8px;
      margin-right: 4px;
      font-size: 10px;
      padding-top: 1px;
    }
  }
  .ol-scale-line-inner{
    color: black;
    font-weight: 600;
    border: 1px solid black;
    border-top:none;
  }
}
@primary-color: #4E63D2;@text-color: var(--color-text-1);@heading-color: var(--color-text-1);@text-color-secondary: #99989F;@item-hover-bg: var(--color-bg-4);@component-background: var(--color-bg-3);@background-color-base: var(--color-bg-3);@disabled-color: #99989f;@height-sm: 16px;@height-base: 24px;@height-lg: 32px;@form-item-margin-bottom: 8px;@border-radius-base: 5px;@border-width-base: 0px;@font-size-base: 12px;